import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import AGM from '../pages/AGM/AGM'
import Motion from '../pages/Motion/Motion'
import Volunteer from '../pages/Volunteer/Volunteer'
import Lobby from '../pages/Lobby/Lobby'
import Petition from '../pages/Petition/Petition'
import Equalities from '../pages/Equalities/Equalities'
import EqualitiesThankYou from '../pages/Equalities/ThankYou'
import JoinerSurvey from '../pages/JoinerSurvey/JoinerSurvey'
import JoinerSurveyThankYou from '../pages/JoinerSurvey/ThankYou'
import NotLikeUs from '../pages/NotLikeUs/NotLikeUs'

// Route, but only if the given environment variable is set
const MaybeRoute = ({varname, path, component}) => {
  if (process.env[varname] && process.env[varname].toLowerCase() === "true") {
    return (<Route exact path={path} component={component} />)
  } else {
    return null
  }
}

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Volunteer} />

      <MaybeRoute varname="REACT_APP_SHOW_MOTION" path="/motion" component={Motion} />

      <MaybeRoute varname="REACT_APP_SHOW_AGM" path="/meeting" component={AGM} />

      <MaybeRoute varname="REACT_APP_SHOW_HIDDEN" path="/lobby" component={Lobby.Rwanda} />
      <MaybeRoute varname="REACT_APP_SHOW_HIDDEN" path="/rmt" component={Lobby.RMT} />
      <MaybeRoute varname="REACT_APP_SHOW_HIDDEN" path="/labour-for-labour" component={Lobby.L4L} />
      <MaybeRoute varname="REACT_APP_SHOW_HIDDEN" path="/bame-labour" component={Lobby.BAMELabour} />
      <MaybeRoute varname="REACT_APP_SHOW_HIDDEN" path="/dark-money" component={Lobby.DarkMoney} />
      <MaybeRoute varname="REACT_APP_SHOW_HIDDEN" path="/bds" component={Lobby.BDS} />
      <MaybeRoute varname="REACT_APP_SHOW_HIDDEN" path="/npf" component={Lobby.NPF} />
      <MaybeRoute varname="REACT_APP_SHOW_HIDDEN" path="/womens-conference" component={Lobby.WomensConference} />
      <MaybeRoute varname="REACT_APP_SHOW_HIDDEN" path="/palestine" component={Lobby.Palestine} />
      <Route exact path="/child-poverty" component={Lobby.ChildPoverty} />
      <Route exact path="/thank-you" component={Lobby.ThankYou} />

      <MaybeRoute varname="REACT_APP_SHOW_HIDDEN" path="/petition" component={Petition.EqualityRoles} />
      <MaybeRoute varname="REACT_APP_SHOW_HIDDEN" path="/tory-laws" component={Petition.ToryLaws} />
      <MaybeRoute
        varname="REACT_APP_SHOW_HIDDEN"
        path="/selections-scandal"
        component={Petition.SelectionsScandal}
      />
      <MaybeRoute
        varname="REACT_APP_SHOW_HIDDEN"
        path="/solidarity-diane-abbott"
        component={Petition.DianeAbbott}
      />
      <Route exact path="/arms-sales" component={Petition.ArmsSales} />
      <MaybeRoute varname="REACT_APP_SHOW_HIDDEN" path="/reinstate-corbyn" component={Petition.ReinstateCorbyn} />
      <Route exact path="/petition/thank-you" component={Petition.ThankYou} />

      <Route exact path="/equalities/thanks" component={EqualitiesThankYou} />
      <Route exact path="/equalities/:email" component={Equalities} />
      <Route exact path="/equalities" component={Equalities} />

      <Route exact path="/new-member-survey/thanks" component={JoinerSurveyThankYou} />
      <Route exact path="/new-member-survey/:email" component={JoinerSurvey} />

      <Route exact path="/what-does-he-know" component={NotLikeUs} />
    </Switch>
  </BrowserRouter>
)

export default Router
