import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { Container, Row, Col } from 'reactstrap'

import { OptionField, SingleLineField } from '../../../components/FormGroup/FormGroup'
import { Head, Media, OptInField, Imprint } from '../../../components/Common/CommonComponents'
import { SubmissionStatus, MediaType, Values } from '../../../components/Common/CommonUtils'
import {
  ThanksPageRedirect, PetitionSubmitButton, PetitionNotice, SignatureCount
} from '../Common/PetitionComponents'
import { handleSubmit } from '../Common/PetitionUtils'

const PUBLIC_URL = process.env.PUBLIC_URL || 'https://community.peoplesmomentum.com'

const content = {
  // Page
  title: `Labour: Suspend Arms Sales to Israel!`,
  intro: `The Lancet medical journal [estimates](https://www.thelancet.com/journals/lancet/article/PIIS0140-6736(24)01169-3/fulltext) that 186,000 deaths or more are "attributable" to Israel's brutal war on Gaza. Meanwhile, the United Nations has [warned](https://news.un.org/en/story/2024/04/11489260) that famine has spread throughout Gaza as more children die from malnutrition.

But as Israel continues to commit countless war crimes, the UK remains complicit by providing arms. A YouGov [poll](https://www.theguardian.com/world/2024/apr/03/majority-of-voters-in-uk-back-banning-arm-sales-to-israel-poll-finds) shows a majority of voters back suspending arms sales to Israel - with 56% in favour of a ban compared to 17% who are against. Keir Starmer may have called for an immediate ceasefire during discussions with Israeli PM Benjamin Netanyahu - but he has stayed largely silent on the issue of arms sales.

We support Labour MP Zarah Sultana's amendment demanding the Government suspends arms sales to Israel. Tell the Labour Government to make the call and help pile on the pressure - sign our petition!`,

  // Share
  share: (vs: Values) => `I just demanded that Labour suspends arms sales to Israel! Click here to make your voice heard:`,

  // Header
  headerType: MediaType.Image,
  urlOrSlug: '/img/arms_sales_banner.jpg',

  // NB
  eventCode: '2024-07 Petition Arms Sales',

  // Petition ID
  petitionId: 6,
}

// Stuff that appears on social media previews
const meta = {
  title: content.title,
  url: `${PUBLIC_URL}/arms-sales?lobby`,
  description: `Sign our petition to demand that Labour suspends arms sales to Israel!`,
  img: `${PUBLIC_URL}/img/arms_sales_banner.jpg`,
  imgAlt: `Labour: Suspend Arms Sales to Israel!`,
}

const ArmsSales = () => {
  // I'll remove this later but I'm paranoid about accidentally sending
  // test emails to an actual MP so I need this for peace of mind,
  console.log(process.env.NODE_ENV)

  const [submissionStatus, setSubmissionStatus] =
    useState<SubmissionStatus>(SubmissionStatus.Unsubmitted)

  const lobbySchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email').required(' (Required)'),
  })

  return (
    <>
      <Head meta={meta} />

      <Media mediaType={content.headerType} urlOrSlug={content.urlOrSlug} alt={meta.imgAlt} />

      <Formik
        initialValues={{
          givenName: '',
          familyName: '',
          email: '',
          mobile: '',
          optIn: false,
          labour: undefined,
          constituency: undefined,
          MP: undefined,
          body: '',
        }}
        validationSchema={lobbySchema}
        onSubmit={handleSubmit(setSubmissionStatus, content)}
      >
        {({ values, errors }) => (
          <Form>
            <Container>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  <ReactMarkdown>{content.intro}</ReactMarkdown>

                  <SignatureCount petitionId={content.petitionId} />

                  <SingleLineField
                    property="givenName"
                    label="First name"
                    errors={errors}
                  />
                  <SingleLineField
                    property="familyName"
                    label="Last name"
                    errors={errors}
                  />
                  <SingleLineField
                    property="email"
                    label="Email address"
                    errors={errors}
                    type="email"
                    required
                  />
                  <SingleLineField
                    property="mobile"
                    label="Mobile phone"
                    errors={errors}
                  />
                  <OptionField
                    property="labour"
                    label="Are you a Labour Party member?"
                    options={['--', 'Yes', 'No']}
                    errors={errors}
                  />
                  <PetitionNotice
                    errors={errors}
                    submissionStatus={submissionStatus}
                  />

                  <OptInField property="optIn" errors={errors} />

                  <PetitionSubmitButton submissionStatus={submissionStatus} />
                </Col>
              </Row>
            </Container>

            <Container style={{marginTop: 0, marginBottom: 15}}>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  * Have we missed something? Let us know at <a href="mailto:info@peoplesmomentum.com">info@peoplesmomentum.com</a>
                </Col>
              </Row>
            </Container>

            <ThanksPageRedirect
              submissionStatus={submissionStatus}
              senderName={values.givenName}
              copy={content.share(values)}
            />
          </Form>
        )}
      </Formik>
      <Imprint/>
    </>
  )
}

export default ArmsSales
