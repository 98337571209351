import EqualityRoles from './EqualityRoles/EqualityRoles'
import ToryLaws from './ToryLaws/ToryLaws'
import SelectionsScandal from './SelectionsScandal/SelectionsScandal'
import DianeAbbott from './DianeAbbott/DianeAbbott'
import ArmsSales from './ArmsSales/ArmsSales'
import ReinstateCorbyn from './ReinstateCorbyn/ReinstateCorbyn'
import ThankYou from './Common/ThankYou'

const Petition = {
  EqualityRoles,
  ToryLaws,
  SelectionsScandal,
  DianeAbbott,
  ArmsSales,
  ReinstateCorbyn,
  ThankYou,
}

export default Petition
